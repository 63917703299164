import React from 'react';
import PNotify from 'pnotify';

import { Events } from 'api';
import { Alert, Loading, PageHeader } from 'components';

import EventIdsTable from './EventIdsTable';

export class EventIdsContainer extends React.Component {
  state = {
    isLoading: true,
    data: [],
  };

  makeRequest = () => {
    Events.getIds().then((res) => {
      this.setState({ isLoading: false, data: res });
    });
  };

  errorNotify = (message) => {
    const errorMessage = !message ? 'The row you requested to edit could not be found' : message;
    PNotify.prototype.options.delay = 5000;
    window.show_stack_topleft('Error', errorMessage, 'error');
  };

  makePost = ({ fieldName, value, pk }) => {
    return Events.updateIds(pk, { [fieldName]: value }).catch((err) => {
      err.response.json().then((json) => {
        this.errorNotify(Object.values(json).join('\n'));
      });
      this.makeRequest();
      throw err;
    });
  };

  onCellEdit = ({ index, column }) => async (e) => {
    let value = e.target.innerHTML;

    const { data } = this.state;
    const row = data[index];

    if (!value || !value.length) {
      // if no value and no previous value, no reason to POST
      if (!row[column.id]) return;

      // null out the value in the database, unique key constraint
      // on these id columns means that empty string would result
      // in a foreign key integrity error
      value = null;
    }

    // if prev value is same as current, no reason to POST
    if (row[column.id] === value) return;

    row[column.id] = value;

    await this.makePost({ fieldName: column.id, value, pk: row.pk });
    data[index] = row;
    this.setState({ data });
  };

  componentDidMount() {
    this.makeRequest();
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.data.length < 1) {
      return (
        <Alert type="warning" icon="fa-exclamation-triangle">
          No events found.
        </Alert>
      );
    }

    return <EventIdsTable onCellEdit={this.onCellEdit} {...this.state} />;
  }
}

const EventIdsPage = () => (
  <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate">
    <PageHeader.Basic title="Event IDs List" center />
    <div className="mt1" />
    <EventIdsContainer />
  </div>
);

export default EventIdsPage;
