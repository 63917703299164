import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { eventIdsPropTypes } from './proptypes';
import {
  yearFormatter,
  titleFormatter,
  seasonFormatter,
  ridActivityNumberFormatter,
  workshopIdFormatter,
  facilitatorRequestingCeusFormatter,
} from '../../helpers/react-table-helpers';

export default class EventIdsTable extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(eventIdsPropTypes),
    onCellEdit: PropTypes.func.isRequired,
  };

  // map onBlur in workshopIdFormatter to props.onCellEdit paradigm
  // figure out how to add back in no blank validation

  render() {
    const columns = [
      {
        Header: 'Year',
        accessor: 'year',
        filterable: true,
        Cell: (props) => yearFormatter({ year: props.value }),
        maxWidth: 60,
      },
      {
        Header: 'Season',
        accessor: 'season',
        filterable: true,
        filterMethod: (filter, row) => {
          if (!filter.value) return true;
          if (filter.value === 'fall') {
            return row.season === 'Fall';
          }
          if (filter.value === 'spring') {
            return row.season === 'Spring';
          }
          if (filter.value === 'summer') {
            return row.season === 'Summer';
          }
          return false;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : ''}
          >
            <option value="">Select season...</option>
            <option value="fall">Fall</option>
            <option value="spring">Spring</option>
            <option value="summer">Summer</option>
          </select>
        ),
        Cell: (props) => seasonFormatter({ season: props.value }),
        maxWidth: 80,
      },
      {
        Header: 'Event',
        accessor: 'title',
        Cell: (props) => titleFormatter({ pk: props.row._original.pk, title: props.value }),
      },
      {
        Header: 'Workshop ID',
        accessor: 'workshop_id',
        Cell: (props) =>
          workshopIdFormatter({
            key: props.column.id,
            ceus: props.row.ceus,
            workshopId: props.row.workshop_id,
            onBlur: this.props.onCellEdit({
              index: props.index,
              column: props.column,
            }),
            __html: props.row.workshop_id,
          }),
        minWidth: 150,
      },
      {
        Header: 'RID Activity #',
        accessor: 'rid_activity_number',
        Cell: (props) =>
          ridActivityNumberFormatter({
            key: props.column.id,
            ceus: props.row.ceus,
            ridActivityNumber: props.row.rid_activity_number,
            onBlur: this.props.onCellEdit({
              index: props.index,
              column: props.column,
            }),
            __html: props.row.rid_activity_number,
          }),
        minWidth: 110,
      },
      {
        Header: 'Fac. Workshop ID',
        accessor: 'facilitator_workshop_id',
        Cell: (props) =>
          workshopIdFormatter({
            key: props.column.id,
            workshopId: props.row.facilitor_workshop_id,
            facilitatorRequestingCeus: props.row.facilitator_requesting_ceus,
            onBlur: this.props.onCellEdit({
              index: props.index,
              column: props.column,
            }),
            __html: props.row.facilitator_workshop_id,
          }),
        minWidth: 150,
      },
      {
        Header: 'Fac. Rid Activity #',
        accessor: 'facilitator_rid_activity_number',
        Cell: (props) =>
          ridActivityNumberFormatter({
            key: props.column.id,
            ceus: props.row.ceus,
            ridActivityNumber: props.row.facilitator_rid_activity_number,
            onBlur: this.props.onCellEdit({
              index: props.index,
              column: props.column,
            }),
            __html: props.row.facilitator_rid_activity_number,
          }),
        minWidth: 135,
      },
      {
        Header: 'Fac. Requested CEUs',
        accessor: 'facilitator_requesting_ceus',
        filterMethod: (filter, row) => {
          if (!filter.value) return true;
          if (filter.value === 'yes') {
            return row.facilitator_requesting_ceus;
          }
          if (filter.value === 'no') {
            return !row.facilitator_requesting_ceus;
          }
          return false;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'All'}
          >
            <option value="">Select Fac. Requesting CEUs...</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        ),
        Cell: (props) =>
          facilitatorRequestingCeusFormatter({
            facilitatorRequestingCeus: props.row.facilitator_requesting_ceus,
          }),
      },
      {
        Header: 'CEUs',
        accessor: 'ceus',
        filterable: false,
      },
      {
        Header: 'Facilitator(s)',
        accessor: 'facilitators',
        filterable: false,
      },
    ];

    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.data}
        columns={columns}
        filterable
        defaultPageSize={40}
        defaultSorted={[
          {
            id: 'year',
            desc: true,
          },
        ]}
      />
    );
  }
}
