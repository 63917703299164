import PropTypes from 'prop-types';
import { EventDictionary } from 'helpers/dictionaries';

export const seasonPropType = PropTypes.oneOf(Object.values(EventDictionary.SEASON_TYPES));

export const eventIdsPropTypes = PropTypes.shape({
  pk: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  season: seasonPropType.isRequired,
  year: PropTypes.string.isRequired,
  survey: PropTypes.string,
  workshop_id: PropTypes.string,
  rid_activity_number: PropTypes.string,
  facilitators: PropTypes.string,
});
